import { hasPermission } from "@avela/avela-authorization-sdk";
import { Button, Flex, Heading, Skeleton } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { RiEdit2Line } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import {
  FORM_AUTHORIZATION_SCOPE,
  useAuthorizationScoped,
} from "src/components/Providers/AuthorizationProvider";
import { Glossary } from "src/components/Text/Glossary";
import { PERMISSION_LEVELS } from "src/constants";
import { hasOrganizations, useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { DisclaimerSectionEditForm } from "src/scenes/orgAdmin/forms/View/EditForms/DisclaimerSectionEditForm";
import { GeneralSectionEditForm } from "src/scenes/orgAdmin/forms/View/EditForms/GeneralSectionEditForm";
import { PreRankingSectionEditForm } from "src/scenes/orgAdmin/forms/View/EditForms/PreRankingSectionEditForm";
import { SchoolRankingEditForm } from "src/scenes/orgAdmin/forms/View/EditForms/SchoolRankingEditForm";
import { GET_CUSTOM_QUESTION_TYPES_BY_ORG } from "src/scenes/orgAdmin/forms/graphql/queries";
import { Answer } from "src/services/formTemplate";
import * as Question from "src/services/formTemplate/question";
import { findSchoolRankingSection } from "src/services/formTemplate/section";
import * as Url from "src/services/url";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { SchoolRankWithTags } from "src/types/schoolRank";
import { InfoBadge } from "../DataDisplay/InfoBadge";
import { DisclaimerQuestionAnswer } from "./DisclaimerQuestionAnswer";
import { QuestionList } from "./QuestionList";
import { SchoolRanking } from "./SchoolRanking";

type EditMode = "edit" | "view" | "disabled";

type Props = {
  form: GQL.GetFormViewById_form_by_pk;
  allSections: AF.Sections<AF.WithId>;
  section: AF.Section<AF.WithId>;
  form_answers: GQL.FormAnswerFragment[];
  grades_answers: GQL.GradesAnswersFragment[];
  address_answers: GQL.AddressAnswersFragment[];
  custom_question_answers: GQL.CustomQuestionAnswersFragment[];
  custom_question_answer_bank_relationships?: GQL.CustomQuestionAnswerBankRelationshipsFragment[];
  schoolsRank: SchoolRankWithTags[];
  tagGroups?: GQL.GetSchoolsRankView_tag_group[];
  editMode: EditMode;
  onEdit: (sectionId: uuid) => void;
  onCloseEdit: () => void;
  onRefetchSchoolsRank?: () => void;
  isLateFormEdit?: boolean;
  formDisclaimer?: GQL.FormDisclaimerFragment;
  isOfferEnabled?: boolean;
  previousFormSchoolIds: uuid[];
};

export const Section: React.FC<Props> = (props) => {
  const {
    form,
    allSections,
    section,
    form_answers,
    grades_answers,
    address_answers,
    custom_question_answers,
    custom_question_answer_bank_relationships,
    schoolsRank,
    tagGroups,
    editMode,
    onEdit,
    onCloseEdit,
    onRefetchSchoolsRank,
    isLateFormEdit,
    formDisclaimer,
    isOfferEnabled = true,
    previousFormSchoolIds,
  } = props;

  const authorizationMap = useAuthorizationScoped(FORM_AUTHORIZATION_SCOPE);
  const organization = useOrganization();

  const hasEditPermissionAdmins = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.ORG_ADMIN,
    HasuraRole.DISTRICT_ADMIN,
  ]);

  // hard-coding to allow school admin edit permissions for some organization(s)
  // TODO: remove when custom user roles allow for school admin to configure more granular form edit permissions
  const hasEditPermissionSchoolAdmin =
    useRequiredHasuraRoles([HasuraRole.SCHOOL_ADMIN]) &&
    hasOrganizations(
      organization,
      "saisd",
      "metco",
      "tulsa",
      "garlandisd",
      "nolaps",
      "newarkcommonapp",
      "phillyprek",
      "philasd",
      "bezosacademy",
      "enrollwcc",
      "oaklandenrolls",
      "aimsk12",
      "efcps",
      "wcsdschoolofchoice",
      "enrollbuffalocharters",
      "leadps",
      "schoolappstl",
      "schoolappkc",
      "pisota",
      "rvla",
      "evcs",
      "tapestry",
      "buffsci",
      "kccs",
      "enterprise",
      "primaryhall",
      "platoacademy"
    );

  const checkEditPermission = useCallback(
    (adminCondition: boolean) => {
      let abacEditAllowed = false;
      if (authorizationMap.hasData()) {
        abacEditAllowed = hasPermission({
          action: {
            type: "Form::Action",
            id: "edit",
          },
          resource: {
            type: "FormSection",
            id: section.key || section.id,
          },
          authorizationMap: authorizationMap.data,
        });
      }

      // allow either roles (admin, orgAdmin, districtAdmin) or school admins with edit permissions
      // TODO: remove when custom user roles allow for school admin to configure more granular form edit permissions
      return adminCondition && abacEditAllowed;
    },
    [authorizationMap, section.key, section.id]
  );

  const formId = form.id;

  // guardianId is used to determine whose answer bank to add to, if custom question answers are added.
  // Assumption: student has a single parent/guardian who filled out the form and has an answer bank.
  // TODO: Handle answer bank for student with more than one parent/guardian.
  // In the case the student has no parent, editing CQT is disabled.
  const guardianId =
    form.person.first_relationship[0]?.second?.id ??
    form.person.second_relationship[0]?.first?.id;

  let showEditButton: boolean;
  let hasEditPermission: boolean;
  let sectionView: React.ReactNode;

  const { remoteData: customQuestionTypesData } = useRemoteDataQuery<
    GQL.GetCustomQuestionTypesByOrg,
    GQL.GetCustomQuestionTypesByOrgVariables
  >(GET_CUSTOM_QUESTION_TYPES_BY_ORG, {
    variables: {
      organization_id: organization.map((org) => org.id).withDefault(""),
    },
  });

  const customQuestionTypes = useMemo(() => {
    return customQuestionTypesData.toNullable()?.custom_question_type ?? [];
  }, [customQuestionTypesData]);

  const hasApplicableQuestions = useMemo(() => {
    if (!("questions" in section)) {
      return false;
    }

    const initialAnswers = Answer.getFormikInitialValues(
      section.questions,
      form_answers,
      grades_answers,
      address_answers,
      custom_question_answers,
      custom_question_answer_bank_relationships
    );

    const rankedSchoolIds = schoolsRank.map(
      (rankedSchool) => rankedSchool.school.id
    );

    const applicableQuestions = Question.getCompleteApplicableQuestions(
      section.questions,
      initialAnswers,
      { rankedSchoolIds, previousFormSchoolIds }
    );

    return applicableQuestions.length > 0;
  }, [
    section,
    form_answers,
    grades_answers,
    address_answers,
    custom_question_answers,
    custom_question_answer_bank_relationships,
    schoolsRank,
    previousFormSchoolIds,
  ]);

  switch (section.type) {
    case AF.PreRankingSectionType:
      hasEditPermission = checkEditPermission(
        hasEditPermissionAdmins || hasEditPermissionSchoolAdmin
      );
      showEditButton = hasEditPermission && editMode !== "edit";
      const schoolRankingSection = findSchoolRankingSection(allSections);

      sectionView =
        hasEditPermission && editMode === "edit" ? (
          <PreRankingSectionEditForm
            formTemplateId={form.form_template.id}
            onCloseEdit={onCloseEdit}
            schoolRankingSectionId={schoolRankingSection?.id}
            questions={section.questions}
            form_answers={form_answers}
            grades_answers={grades_answers}
            address_answers={address_answers}
            custom_question_answers={custom_question_answers}
            custom_question_answer_bank_relationships={
              custom_question_answer_bank_relationships
            }
            customQuestionTypes={customQuestionTypes}
            rankedSchools={schoolsRank}
            formId={formId}
            guardianId={guardianId}
            applicant={form.person}
            previousFormSchoolIds={previousFormSchoolIds}
            rankingEnabled={schoolRankingSection?.rankingEnabled ?? false}
          />
        ) : (
          <QuestionList
            form={form}
            questions={section.questions}
            form_answers={form_answers}
            grades_answers={grades_answers}
            address_answers={address_answers}
            custom_question_answers={custom_question_answers}
            custom_question_answer_bank_relationships={
              custom_question_answer_bank_relationships
            }
            rankedSchoolIds={schoolsRank.map((rank) => rank.school.id)}
            previousFormSchoolIds={previousFormSchoolIds}
          />
        );
      break;
    case AF.GeneralSectionType:
      hasEditPermission = checkEditPermission(
        hasEditPermissionAdmins || hasEditPermissionSchoolAdmin
      );
      showEditButton =
        hasEditPermission && editMode !== "edit" && hasApplicableQuestions;
      sectionView =
        hasEditPermission && editMode === "edit" ? (
          <GeneralSectionEditForm
            onCloseEdit={onCloseEdit}
            questions={section.questions}
            form_answers={form_answers}
            grades_answers={grades_answers}
            address_answers={address_answers}
            custom_question_answers={custom_question_answers}
            custom_question_answer_bank_relationships={
              custom_question_answer_bank_relationships
            }
            customQuestionTypes={customQuestionTypes}
            rankedSchools={schoolsRank}
            formId={formId}
            guardianId={guardianId}
            applicant={form.person}
            previousFormSchoolIds={previousFormSchoolIds}
          />
        ) : (
          <QuestionList
            form={form}
            questions={section.questions}
            form_answers={form_answers}
            grades_answers={grades_answers}
            address_answers={address_answers}
            custom_question_answers={custom_question_answers}
            custom_question_answer_bank_relationships={
              custom_question_answer_bank_relationships
            }
            rankedSchoolIds={schoolsRank.map((rank) => rank.school.id)}
            previousFormSchoolIds={previousFormSchoolIds}
          />
        );
      break;
    case AF.SchoolRankingSectionType:
      hasEditPermission = checkEditPermission(hasEditPermissionAdmins);
      showEditButton = hasEditPermission && editMode !== "edit";
      sectionView =
        hasEditPermission && editMode === "edit" ? (
          <SchoolRankingEditForm
            enrollmentPeriodId={form.form_template.enrollment_period_id}
            formId={formId}
            formTemplateId={form.form_template.id}
            applicant={form.person}
            allSections={allSections}
            schoolRankingSection={section}
            schoolsRank={schoolsRank}
            onCloseEdit={onCloseEdit}
          />
        ) : (
          <>
            <SchoolRanking
              schoolsRank={schoolsRank}
              enrollmentPeriodId={form.form_template.enrollment_period_id}
              onRefetchSchoolsRank={onRefetchSchoolsRank}
              isOfferEnabled={isOfferEnabled}
              tagGroups={tagGroups}
              formId={formId}
              rankingEnabled={section.rankingEnabled ?? false}
            />

            {isLateFormEdit && (
              <Button
                as={RouterLink}
                to={organization
                  .map((org) => Url.Parent.Form.lateEdit(org, form.id))
                  .withDefault("#")}
              >
                <Glossary>Edit schools</Glossary>
              </Button>
            )}
          </>
        );
      break;
    case AF.DisclaimerSectionType:
      hasEditPermission = checkEditPermission(
        hasEditPermissionAdmins || hasEditPermissionSchoolAdmin
      );
      showEditButton = hasEditPermission && editMode !== "edit";
      sectionView =
        hasEditPermission && editMode === "edit" ? (
          <DisclaimerSectionEditForm
            formId={formId}
            disclaimerSection={section}
            formDisclaimer={formDisclaimer}
            onCloseEdit={onCloseEdit}
          />
        ) : (
          <DisclaimerQuestionAnswer
            disclaimer={section.disclaimer}
            signature={formDisclaimer?.signature}
            signedAt={formDisclaimer?.signed_at}
          />
        );
      break;
    default:
      const _exhaustiveCheck: never = section;
      return _exhaustiveCheck;
  }

  return (
    sectionView && (
      <Flex
        direction="column"
        gap={4}
        paddingX={2}
        paddingY={6}
        _first={{ paddingTop: 0 }}
        _last={{ paddingBottom: 0 }}
        _notLast={{ borderBottom: "1px solid", borderBottomColor: "gray.300" }}
      >
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height="2rem"
        >
          <Flex gap={3} alignItems="center">
            <Heading as="h2" fontSize="md" fontWeight="600">
              {section.title}
            </Heading>

            {section.permissionLevel && (
              <InfoBadge status={PERMISSION_LEVELS.admin} />
            )}
          </Flex>

          {authorizationMap.isLoading() && (
            <Skeleton width="4rem" height="1rem" />
          )}

          {showEditButton ? (
            <Button
              variant="outline"
              colorScheme="gray"
              leftIcon={<RiEdit2Line />}
              onClick={() => onEdit(section.id)}
              isDisabled={editMode === "disabled"}
            >
              Edit
            </Button>
          ) : null}
        </Flex>
        {hasEditPermission && editMode === "edit" ? (
          <Flex direction="column">{sectionView}</Flex>
        ) : (
          sectionView
        )}
      </Flex>
    )
  );
};
