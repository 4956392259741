import { Outlet, Route } from "react-router-dom";
import { PublicLayout } from "./public";
import { AccountLookup } from "./public/accountLookup";
import * as Url from "src/services/url";
import { FeatureFlagProvider } from "src/components/Providers/FeatureFlagProvider";
import { PageRedirect } from "./public/PageRedirect";

export const UnauthorizedAvelaRoutes = (
  <Route
    element={
      <PublicLayout>
        <FeatureFlagProvider>
          <Outlet />
        </FeatureFlagProvider>
      </PublicLayout>
    }
  >
    <Route path={Url.Parent.AccountLookup.path} element={<AccountLookup />} />
    <Route path={Url.pageRedirectPath} element={<PageRedirect />} />
  </Route>
);
