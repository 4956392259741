import { useEffect, useState } from "react";
import { useQueryParams } from "src/hooks/useQueryParams";
import { useEnv } from "src/services/env";
import { z } from "zod";
import * as RD from "src/types/remoteData";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { GenericError } from "src/components/Feedback/GenericError";
import { NotFound } from "src/components/Feedback/NotFound";
import { Navigate, useLocation } from "react-router-dom";

const JsonSchema = z.object({
  orgPath: z.string(),
});

const ErrorSchema = z.object({
  message: z.string(),
  error: z.string().optional(),
});

/**
 * PageRedirect Component
 *
 * A utility component for handling redirects based on clientId
 *
 * This component takes a clientId and optional redirectTo path via query params,
 * fetches the corresponding organization path from the organization service,
 * and redirects to the appropriate URL within that organization's context.
 *
 * Example Usage for Email Verification:
 *
 * When Auth0 redirect email verification link to:
 * /page-redirect?clientId=123&redirectTo=/email-verification&success=true
 *
 * The component will:
 * 1. Extract clientId=123 and redirectTo=/email-verification
 * 2. Fetch org path (e.g. "acme-corp") for clientId=123
 * 3. Redirect to: /acme-corp/email-verification?success=true
 *
 * This ensures users land in the correct organization context while preserving
 * other relevant query parameters.
 */
export const PageRedirect: React.FC = () => {
  const [remoteData, setRemoteData] = useState<
    RD.RemoteData<
      { status: number; message: string; error?: string },
      { orgPath: string }
    >
  >(RD.notAsked());
  const { clientId, redirectTo } = useQueryParams();
  const location = useLocation();
  const env = useEnv();
  useEffect(() => {
    (async () => {
      try {
        setRemoteData(RD.loading());
        const response = await fetch(
          `${env.REACT_APP_ORGANIZATION_SERVICE_URL}/orgPath?clientId=${clientId}`
        );

        if (response.status !== 200) {
          const json = ErrorSchema.parse(await response.json());
          const error = { status: response.status, ...json };
          console.error(error);
          setRemoteData(RD.failure(error));
          return;
        }

        const json = JsonSchema.parse(await response.json());
        setRemoteData(RD.success(json));
      } catch (error) {
        console.error(error);
        setRemoteData(RD.failure({ status: 500, message: "Unexpected error" }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <RemoteDataView
      error={(error) => {
        if (error.status === 404) {
          return <NotFound />;
        }
        return <GenericError message={error.message} />;
      }}
      remoteData={remoteData}
    >
      {(data) => {
        // Get current search params
        const searchParams = new URLSearchParams(location.search);

        // Remove clientId and redirectTo from the search params
        searchParams.delete("clientId");
        searchParams.delete("redirectTo");

        // Create the base redirect URL
        const baseRedirectUrl = `/${data.orgPath}${redirectTo ?? ""}`;

        // Append the search params to create the final URL
        const redirectUrl = searchParams.toString()
          ? `${baseRedirectUrl}?${searchParams.toString()}`
          : baseRedirectUrl;

        return <Navigate to={redirectUrl} />;
      }}
    </RemoteDataView>
  );
};
